import gsap, { Power2 } from 'gsap';

import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import { isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import { KoraButtons } from '../components/KoraButtons';
import { GetBy } from '../_app/cuchillo/core/Element';
import Cursor from '../_app/cuchillo/cursor/Cursor';
import Sketch from '../3D/Sketch';
import Header from '../layout/Header';

export default class Default extends Page {
  // _newsletter;
  _canvas;
  _curtain;

  constructor() {
    super();

    Videos.init();
    Acordions.init();
    KoraButtons.init();

    this._curtain = GetBy.id('Curtain');
    this._canvas = GetBy.id('scene-kora');

    // this._newsletter = new FormValidator(GetBy.id('mc-embedded-subscribe-form'));
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Cursor.reset();
    Keyboard.mountPage(this.id);

    if (this.isFirstTime) {
      Sketch.start();
      gsap.set(this._curtain, { opacity: 0 });
    }
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    if (!this.isFirstTime) {
      gsap.set(this._canvas, { opacity: 1 });
      gsap.to(this._curtain, {
        opacity: 0,
        duration: .5,
        delay: .3,
        ease: Power2.easeOut,
        onComplete: () => {
          super.afterShow();
          Scroll.start();
          Scroll.show();
        }
      });
    } else {
      super.afterShow();
      Scroll.start();
      Scroll.show();
    }

    Header.show();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();

    // if (this._newsletter) this._newsletter.dispose();

    gsap.to(this._curtain, {
      opacity: 1,
      duration: .5,
      ease: Power2.easeOut
    });
    gsap.to(this._canvas, {
      opacity: 0,
      duration: .3,
      ease: Power2.easeOut
    });
  }

  hide__effect() {
    Cursor.hide();
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.dispose();
    super.afterHide();
    // Sketch.stop();
  }

  //RESIZE
  resize() {
    super.resize();
    Acordions.resize();
    KoraButtons.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage('default', Default);