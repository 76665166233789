import gsap, { Power2 } from "gsap";

import _Header from '../_app/cuchillo/layout/Header';
import { GetBy } from '../_app/cuchillo/core/Element';
import { KoraButton, KoraButtons } from '../components/KoraButtons';
import EventDispatcher from "../_app/cuchillo/core/EventDispatcher";

export default class Header extends _Header {
  static container = GetBy.class("header__bar")[0];
  static btnBook;
  static firstTime = false;
  static optionsText = {
    texts: this.container.getAttribute("data-book-text").split(","),
    actual: 0,
    time: 4000,
    next: () => {
      this.optionsText.actual = this.optionsText.actual + 1 === this.optionsText.texts.length ? 0 : this.optionsText.actual + 1;
      return this.optionsText.texts[this.optionsText.actual];
    }
  }

  static options = {
    show: {
      duration: 1,
      delay: 0,
      ease: Power2.easeOut
    },
    hide: {
      duration: .6,
      delay: 0,
      ease: Power2.easeIn
    }
  }

  static init() {
    super.init();
    this.btnBook = KoraButtons.get("HeaderButtonBook");
    this.setupTextChange();
    this.directHide();
  }

  static setupTextChange() {
    setInterval(()=> {
      if(this.btnBook.isHover) return;

      this.btnBook.changeText(this.optionsText.next());
    }, this.optionsText.time)
  }

  static show(__call) {
    //this.btnBook.state = KoraButton.STATE_LARGE_ICON;
    super.show(__call);
  }

  static show__effect() {
    if (Header.firstTime) {
      EventDispatcher.dispatchEvent(_Header.ON_SHOW);

      gsap.to(Header.btnBook.container, {
        y: 0,
        duration: 1,
        delay: 1,
        ease: this.options.show.ease,
      });

      gsap.to(this, {
        y: 0,
        duration: 1,
        delay: 1,
        ease: this.options.show.ease,
        onComplete: () => {
          this.isShowing = false;
        }
      });

      Header.firstTime = false;
    } else {
      gsap.to(Header.btnBook.container, {
        y: 0,
        duration: this.options.show.duration,
        delay: this.options.show.delay,
        ease: this.options.show.ease,
      });

      super.show__effect();
    }
  }

  static hide(__call) {
    if (this.isShow) {
      //this.btnBook.state = KoraButton.STATE_MINI_TEXT;
      this.isShow = false;
    }
  }

  static directHide() {
    super.directHide();

    gsap.set(Header.btnBook.container, {
      y: -this.height
    });
  }
}
