import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import HighlightTextController from '../3D/HighlightTextController';
import Sketch from '../3D/Sketch';
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { ScrollItem__Default } from './ScrollItem__Default';

class ScrollItem__HighlightText extends ScrollItem__Default {
  controller;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    if (!isSmartphone) {
      this.controller = new HighlightTextController();

      this._call = () => {
        this.loop();
      }

      const bg = GetBy.class('__bg', __link)[0];
      let src;

      if (bg.classList.contains('--video')) src = GetBy.selector('.__bg video', bg)[0];
      else src = GetBy.selector('.__bg img', bg)[0];

      this.controller.setupBG(bg, src);

      this.onShow = () => {
        this.showEffect();

        gsap.ticker.add(this._call);
        this.controller.start();
      };
      this.onHide = () => {
        this.controller.update({ progress: 1, y: this.height });
        this.controller.stop();
        gsap.ticker.remove(this._call);
        Sketch.newSeed();
      };
    } else {
      this.onShow = () => this.showEffect();
      this.onHide = () => { };
    }

    this.onMove = () => { };
  }

  loop() {
    if (this.controller) this.controller.update({ progress: this.progress, y: this.realY });
  }

  resize(__w, __h) {
    super.resize(__w, __h);
    if (this.controller) this.controller.resize();
  }

  dispose() {
    this.onHide();
    if (this.controller) this.controller.dispose();
    super.dispose();
  }
}

Scroll._registerClass('highlight-text', ScrollItem__HighlightText);
