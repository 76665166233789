import { PlaneGeometry, ShaderMaterial, DoubleSide } from 'three';
import gsap, { Power2, Power3, Linear, Expo, Power4 } from 'gsap';
import { SlowMo } from "gsap/EasePack";

// import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Basics, isSmartphone } from '../_app/cuchillo/core/Basics';
import CanvasColorTexture from '../_app/cuchillo/3D/CanvasColorTexture';
import { IMAGE_VERTEX } from '../_app/cuchillo/shaders/image-vertex';
import Image from '../_app/cuchillo/3D/Image';
import PortalVideo from './PortalVideo';
import PortalImage from './PortalImage';
// import DebugPane from './DebugPaneBillboard';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { PORTAL_FRAGMENT } from '../shaders/portal-fragment';
import { TURBULENT_FRAGMENT } from '../_app/cuchillo/shaders/turbulent-displacement-fragment';
import Sketch from './Sketch';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';

gsap.registerPlugin(SlowMo);

export const SETTINGS = {
    opacity: 0,
    imageScale: 1.15,
    maskScale: 1,
    progress: 0,
    smoothnessPortal: .1,
    smoothness: .15,
    scale: 3,
    scalePortal: 20,
    intensity: 25,
    mouseX: 15,
    mouseY: 10,
    mouseZ: 80,
    rotX: 20,
    rotY: 4,
    seed: .1,
    darken: .3
}

export default class BillboardControllerMobile {
    container;
    planes = [];
    texts = [];
    bg;
    bgImg;
    zIndex = [0, .5, 20, 25];
    sliderI = 0;

    isEnabled = false;

    positions = {
        down: {
            x: 0,
            y: 0
        },
        distance: {
            x: 0,
            y: 0
        },
        up: {
            x: 0,
            y: 0
        }
    };

    constructor(__container) {
        this.container = __container;

        const holder = GetBy.class("__holder-portals")[0];
        holder.scrollLeft = Metrics.CENTER_X;

    }

    setupBG(target) {
        this.bg = target;
    }

    setupPortals(items) {
        for (let i = 0; i < items.length; i++) {
            if (items[i].classList.contains("--text")) {
                this.texts.push(items[i]);
            } else {
                this.planes.push(items[i]);
            }
        }
    }

    showBillboard(__call) {
        if (this.isEnabled) return;

        Scroll.stop();

        //SHOW BG
        let delay = .8;
        gsap.set(this.bg, { opacity: 0 });
        gsap.to(this.bg, { opacity: 1, duration: .4, ease: Power2.easeIn, delay: delay });

        this.bgImg = GetBy.selector('img', this.bg)[0];
        gsap.set(this.bgImg, { scaleX: 1.5, scaleY: 1.5 });
        gsap.to(this.bgImg, {
            scaleX: 1.25,
            scaleY: 1.25,
            duration: 1.2,
            ease: Expo.easeOut,
            delay: delay - .1,
            onComplete: () => {
                this.isEnabled = true;
                if (__call) __call();
            }
        });

        //SHOW TEXT
        gsap.set(this.texts, { opacity: 0, z: 300, y: 25 });
        gsap.to(this.texts[0], { opacity: 1, duration: .4, ease: Power2.easeIn, delay: 0 });
        gsap.to(this.texts[1], { opacity: 1, duration: .4, ease: Power2.easeIn, delay: .1 });
        gsap.to(this.texts[2], { opacity: 1, duration: .4, ease: Power2.easeIn, delay: .2 });

        delay += 1.25;
        gsap.to(this.texts[0], { z: this.zIndex[1], duration: delay + .3, ease: Linear.easeNone, delay: 0 });
        gsap.to(this.texts[1], { z: this.zIndex[1], duration: delay + .4, ease: Linear.easeNone, delay: .1 });
        gsap.to(this.texts[2], { z: this.zIndex[1], duration: delay + .5, ease: Linear.easeNone, delay: .2 });

        gsap.to(this.texts[0], { opacity: 0, duration: .4, ease: Power2.easeInOut, delay });
        gsap.to(this.texts[1], { opacity: 0, duration: .4, ease: Power2.easeInOut, delay });
        gsap.to(this.texts[2], { opacity: 0, duration: .4, ease: Power2.easeInOut, delay });

        //PORTALS POSITION
        gsap.set(this.planes, { opacity: 0, z: 1500 });

        delay -= .5;
        gsap.to(this.planes, { opacity: 1, duration: .4, ease: Power2.easeIn, delay: delay + .3 });
        gsap.to(this.planes[2], { z: 0, duration: 2, ease: Power4.easeOut, delay });
        gsap.to(this.planes[1], { z: 0, duration: 2, ease: Power4.easeOut, delay: delay + .1 });
        gsap.to(this.planes[0], { z: 0, duration: 2, ease: Power4.easeOut, delay: delay + .2 });
    }

    start() { }

    stop() { }

    update(opts) {
        if (!this.isEnabled) return;

        const { progress } = opts;

        //BG
        let normAlpha = Maths.normalize(.9, .8, progress);
        let alpha = Maths.clamp(Maths.lerp(1, 0, normAlpha), 0, 1);
        let normScale = Maths.normalize(1, .5, progress);
        let scale = Maths.lerp(1.25, 1, normScale);

        gsap.set(this.bg, { opacity: alpha });
        // gsap.set(this.bgImg, { scaleX: scale, scaleY: scale });

        //PORTALS
        normAlpha = Maths.normalize(.7, .66, progress);
        alpha = Maths.clamp(Maths.lerp(1, 0, normAlpha), 0, 1);
        normScale = Maths.normalize(.75, .5, progress);
        scale = Maths.lerp(1, SETTINGS.imageScale, normScale);

        for (let i = 0; i < this.planes.length; i++) {
            const plane = this.planes[i];

            gsap.to(plane, { alpha });
            // const img = GetBy.selector('img', plane)[0];
            // gsap.to(img, { scaleX: scale, scaleY: scale });
        }
    }

    resize() { }

    dispose() { }
}
