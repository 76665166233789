import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import BillboardController from '../3D/BillboardController';
import { Basics, isSmartphone, isSmartphonePortrait, isMobile } from '../_app/cuchillo/core/Basics';
import Sketch from '../3D/Sketch';
import BillboardControllerMobile from '../3D/BillboardControllerMobile';
import BG from '../_app/cuchillo/layout/Background';
import { Sizes } from '../_app/cuchillo/core/Sizes';

class ScrollItem__WebGLSketchBillboard extends VScroll_Item {
  controller;
  isShow;
  scroller;
  isAnimated = false;

  windowWidth = 0;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    if (isSmartphone) {
      this.controller = new BillboardControllerMobile(__link);
    } else {
      this.controller = new BillboardController();
    }

    this.scroller = GetBy.id('billboard-scroller');
    gsap.set(this.scroller, { opacity: 0, scaleX: 1.25, scaleY: 1.25 });

    // if (isSmartphonePortrait) this.scroller.style.top = `${window.innerHeight - 100}px`;

    this._call = () => {
      this.loop();
    }

    this._scroll = () => {
      this.scroll();
    }

    this.scroller.addEventListener(Basics.clickEvent, this._scroll);

    this.controller.setupBG(GetBy.class('__bg', __link)[0], GetBy.selector('.__bg img', __link)[0]);
    this.controller.setupPortals(GetBy.class('__target', __link));

    this.onShow = () => {
      if (!this.isAnimated) {
        this.controller.showBillboard(() => {
          Scroll.start();
          
          this.item.setAttribute("data-palette","primary-fluor");
          BG._changes[0].palette = "primary-fluor";
          BG.changeBG("#FFFFFF", null, 0);
        });

        gsap.to(this.scroller, { opacity: 1, scaleX: 1, scaleY: 1, duration: 1, delay: 2.5, onComplete: () => { this.isAnimated = true; } });
      }

      gsap.ticker.add(this._call);
      this.controller.start();

    };
    this.onHide = () => {
      this.controller.update({ progress: 1, y: this.height });
      this.controller.stop();
      gsap.ticker.remove(this._call);
      Sketch.newSeed();
    };
    this.onMove = () => {
      if (!this.isAnimated) return;

      if (!isSmartphone) {
        if (this.realY < -0.02) {
          gsap.to(this.scroller, { opacity: 0, duration: 1, onComplete: () => { this.scroller.style.pointerEvents = 'none'; } });
        } else {
          gsap.to(this.scroller, { opacity: 1, duration: 1, onComplete: () => { this.scroller.style.pointerEvents = 'initial'; } });
        }
      }
    };
  }

  loop() {
    this.controller.update({ progress: this.progress, y: this.realY });
  }

  scroll() {
    Scroll.goto(this.item.nextElementSibling.offsetTop);
  }

  resize(__w, __h) {
    super.resize(__w, __h);
    this.controller.resize();

    if (this.windowWidth !== window.innerWidth) {
      this.windowWidth = window.innerWidth;
      if (window.innerWidth <= Sizes.SMARTPHONE) this.scroller.style.top = `${window.innerHeight - 80}px`;
      else this.scroller.style.top = '';
    }
  }

  dispose() {
    this.onHide();
    this.controller.dispose();
    this.scroller.removeEventListener(Basics.clickEvent, this._scroll);
    super.dispose();
  }
}

Scroll._registerClass('block-billboard-home', ScrollItem__WebGLSketchBillboard);
