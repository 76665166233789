import Header from '../layout/Header';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Default from './Default';

export default class Home extends Default {
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor() {
    super();
  }

  beforeShow() {
    super.beforeShow();

    if (this.isFirstTime) {
      Header.firstTime = true;
    }
  }
}

ControllerPage._addPage('home', Home);
