import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { ScrollItem__Default } from './ScrollItem__Default';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import { Maths } from '../_app/cuchillo/utils/Maths';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { GetBy } from '../_app/cuchillo/core/Element';
import BezierEasing from 'bezier-easing'
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { isSmartphone, isTouch } from '../_app/cuchillo/core/Basics';

class ScrollItem__SliderCards3D extends ScrollItem__Default {
    _call;
    _slider;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        if(isTouch) return;

        this._slider = new SliderScroll(GetBy.class("slider-cards-3D", __link)[0], {
            itemClass: ScrollItem__SliderCards3D__Item,
            interaction: !!isSmartphone,
            hasScrollbar: true,
            domScrollbar: GetBy.class("scrollbar", __link)[0],
            domControls: GetBy.class("__controls", __link)[0],
            hasLimits: isSmartphone,
            y: 0,
        });

        this.onShow = () => this.showEffect();
        this.onHide = () => this.hideEffect();
        this.onMove = (position) => {
            for (let i = 0; i < this._slider.total; i++) {
                this._slider._scroll._items[i].yParent = position.y;
                this._slider._scroll._items[i].drawHook();
            }

            //this._slider.goto_percetage(1 - this.progressInside);
        };
    }

    showEffect() {
        if(!isTouch) {
            this._slider.start();
        }
        super.showEffect();
    }

    hideEffect() {
        if(!isTouch) {
            this._slider.stop();
        }
        super.hideEffect();
    }

    resize(w, h) {
        super.resize(w, h);
        if (this._slider) this._slider.resize();
    }

    loop() {
        if (this._slider) this._slider.loop();
    }

    dispose() {
        if (this._slider) {
            this._slider.stop();
            this._slider.dispose();
        }

        super.dispose();
    }
}

class ScrollItem__SliderCards3D__Item extends VScroll_Item {
    static easing = BezierEasing(0.12, 0.67, 0.36, 0.93);
    static eaasingBack = BezierEasing(.23, .01, .27, .95);

    yParent = 0;

    options = {
        default: {
            yShow: .5,
            zShow: 0.02,
            yHide: -.1,
            zHide: -0.02
        },
        desktop: {
            yShow: 60,
            yHide: 60,
            zShow: 60,
            zHide: 60
        },
        smartphone: {
            yShow: 60,
            yHide: 60,
            zShow: 60,
            zHide: 60
        }
    }


    hasMove = false;
    isEmpty = false;
    hasHiddenEnabled = false;

    _scroll;
    _card;
    _tapa;


    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
        this._card = GetBy.class(this.item.getAttribute("data-card"), this.item.parentNode)[0];
        this._tapa = GetBy.class("tapa", this._card)[0];
        this.isEmpty = !this._card;
        this._scroller = __scroller;

        this.setupOptions();
    }

    setupOptions() {
        /*if(isSmartphone) {
            this.options.default = this.options.smartphone;
        } else {
            this.options.default = this.options.desktop;
        }*/
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    mouseOver() { }
    mouseDown() { }
    mouseUp() { }
    drawHook() {
        if (this.isEmpty) return;
        if (isSmartphone) this.drawPhone();
        else this.drawDesktop();
    }

    drawDesktop() {
        const rot = 0;//this._scroller.speed * .004;
        const correctedProgress = this.progress;
        const yOffset = (this.yParent * (correctedProgress - .5)) * .30;

        if (correctedProgress < .5) { //SHOW
            const progress = Maths.normalize(.5, 0, correctedProgress);
            const z = Maths.lerp(this.options.desktop.zHide, 0, progress);
            const y = Maths.lerp(this.options.desktop.yHide, 0, progress) + yOffset;
            const x = Maths.lerp(this.options.desktop.xShow, 0, progress);

            this._card.setAttribute("data-p", this.progress)
            this._card.style["z-index"] = Math.round(z);
            this._card.style[CSS.transform] = CSS.translate3D(x, y, z) + " " + CSS.rotate3D(1, 0, 0, rot);
            this._card.style.opacity = 1;
            this._tapa.style.opacity = Math.max(0, progress * -.21);

        } else { //HIDE
            const progress = Maths.normalize(1, .5, correctedProgress);
            const z = Maths.lerp(0, this.options.desktop.zShow, progress);
            const y = Maths.lerp(0, this.options.desktop.yShow, progress) + yOffset;
            const progressAlpha = Maths.normalize(0.2, .4, progress);

            this._card.setAttribute("data-p", this.progress)
            this._card.style["z-index"] = Math.round(z);
            this._card.style[CSS.transform] = CSS.translate3D(0, y, z) + " " + CSS.rotate3D(1, 0, 0, rot);
            this._card.style.opacity = progressAlpha;
            this._tapa.style.opacity = 0;
        }
    }

    drawPhone() {
        const correctedProgress = this.progress;
        const yOffset = (this.yParent * (correctedProgress - .5)) * .1;

        if (correctedProgress < .5) { //SHOW
            const progress = Maths.normalize(.5, 0, correctedProgress);
            const z = Maths.lerp(this.options.desktop.zHide, 0, progress);
            const y = Maths.lerp(this.options.desktop.yHide, 0, progress) + yOffset;
            const x = Maths.lerp(this.options.desktop.xShow, 0, progress);
            const rot = Maths.lerp(this.options.desktop.xShow, 0, progress);

            this._card.setAttribute("data-p", this.progress)
            this._card.style["z-index"] = Math.round(z);
            this._card.style[CSS.transform] = CSS.translate3D(x, y, z) + " " + CSS.rotate3D(0, 1, 0, rot);
            this._tapa.style.opacity = Math.max(0, (correctedProgress - .5) * -.34);

        } else { //HIDE
            const progress = Maths.normalize(1, .5, correctedProgress);
            const z = Maths.lerp(0, this.options.desktop.zShow, progress);
            const y = Maths.lerp(0, this.options.desktop.yHide, progress) + yOffset;
            const x = Maths.lerp(0, this.options.desktop.xHide, progress);
            const rot = Maths.lerp(0, 2, progress);

            this._card.setAttribute("data-p", this.progress)
            this._card.style["z-index"] = Math.round(z);
            this._card.style[CSS.transform] = CSS.translate3D(x, y, z) + " " + CSS.rotate3D(0, 1, 0, rot);
            this._tapa.style.opacity = 0;
        }
    }

    resize(w, h) {
        super.resize(w, h);

        if (isSmartphone) {
            this.options.desktop = {
                yShow: 0,
                yHide: 0,
                zShow: w * .05,
                zHide: -w * .01,
                xShow: w * .12,
                xHide: -w * 1.03,
            }
        } else {
            this.options.desktop = {
                yShow: (w * 707) / 1415,
                yHide: (w * -221) / 1415,
                zShow: (w * 28) / 1415,
                zHide: (w * -28) / 1415,
                xShow: w * .12,
                xHide: -w * .7,
                rot: 25,
            }
        }

    }

    show() {
        super.show();
    }

    hide() {
        super.hide();
    }
}

Scroll._registerClass('SliderCards3D', ScrollItem__SliderCards3D);


