import { OrthographicCamera, PerspectiveCamera, Scene, Vector2, AxesHelper, Vector3, WebGLRenderer } from "three";

import { isSmartphone } from "../core/Basics";
import { GetBy } from "../core/Element";
import { Metrics } from "../core/Metrics";
import { Sizes } from "../core/Sizes";

export default class WebGLSketch {
	_started = false;
	_paused = false;
	renderer;
	scene;
	camera;
	// clock;
	size;
	// controls;
	// raycaster;
	// mouse = new THREE.Vector2();

	defaults = {
		container: 'scene',
		antialias: true,
		alpha: true,
		ortho: false,
		controls: false,
		fov: 60,
		cameraPos: new Vector3(0, 0, 0),
		lookAt: new Vector3(),
		near: .1,
		far: 15000,
		clearColor: '#000000',
		clearColorAlpha: 1
	}

	constructor(opts = {}) {
		this.defaults = {
			...this.defaults,
			...opts
		};

		const container = GetBy.id(this.defaults.container);
		const { width, height } = container.getBoundingClientRect();
		this.size = this.defaults.size ? this.defaults.size : new Vector2(width, height);
		this.scene = new Scene();

		this.renderer = new WebGLRenderer({
			canvas: container,
			antialias: this.defaults.antialias,
			alpha: this.defaults.alpha
		});

		this.renderer.setClearColor(this.defaults.clearColor, this.defaults.clearColorAlpha);
		this.renderer.setSize(this.size.x, this.size.y);
		this.renderer.setPixelRatio(Sizes.RATIO_CANVAS);

		if (this.defaults.ortho) {
			this.camera = new OrthographicCamera(
				-this.size.x / 2,
				this.size.x / 2,
				this.size.y / 2,
				-this.size.y / 2,
				this.defaults.near,
				this.defaults.far,
			);
			this.camera.position.copy(this.defaults.cameraPos);
		}
		else {
			this.camera = new PerspectiveCamera(
				this.defaults.fov,
				this.size.x / this.size.y,
				this.defaults.near,
				this.defaults.far
			);
			this.camera.position.copy(this.defaults.cameraPos);
			this.camera.lookAt(this.defaults.lookAt);
			this.camera.fov = 2 * Math.atan(this.size.x / this.camera.aspect / (2 * this.camera.position.z)) * (180 / Math.PI);
			this.camera.updateProjectionMatrix();
		}
		this.scene.add(this.camera);

		// this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		// this.controls.enabled = true;
		// this.raycaster = new THREE.Raycaster();

		if (this.defaults.debug) {
			const axesHelper = new AxesHelper(2000);
			this.scene.add(axesHelper);
			//this.defaults.controls = true;
		}

		// if (this.defaults.controls) {
		// 	this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		// 	this.controls.autoRotate = true;
		// 	this.controls.enableDamping = true;
		// 	this.controls.enableZoom = false;
		// 	this.controls.enabled = true;
		// }

		// this.clock = new Clock();
	}

	get domElement() {
		return this.renderer.domElement;
	}

	get time() {
		return this.clock.getElapsedTime();
	}

	start() {
		if (this._started) return;
		this._started = true;
		// this.clock.start();
		this.addEventListeners();
	}

	pause() {
		if (!this._started) return;
		if (this._paused) return;
		this._paused = true;
		// this.clock.pause();
	}

	resume() {
		if (!this._started) return;
		if (!this._paused) return;
		this._paused = false;
		// this.clock.start();
	}

	addEventListeners() { }

	removeEventListeners() { }

	update() { }

	render() {
		this.renderer.render(this.scene, this.camera);
	}

	loop(opts) {
		if (!this._started || this._paused) return;

		if (this.defaults.controls) this.controls.update();

		this.update(opts);
		this.render();
	}

	resize() {
		if (isSmartphone) return;

		this.domElement.style = "";
		const { width, height } = this.domElement.getBoundingClientRect();

		if (width === this.size.x && height === this.size.y) return;

		this.size.set(width, height);
		this.renderer.setSize(this.size.x, this.size.y);

		if (this.camera.type == "PerspectiveCamera") {
			this.camera.aspect = this.size.x / this.size.y;
			this.camera.position.z = this.defaults.cameraPos.z;
			this.camera.fov = 2 * Math.atan(this.size.x / this.camera.aspect / (2 * this.camera.position.z)) * (180 / Math.PI);
			this.camera.updateProjectionMatrix();
		} else {
			this.camera.left = -this.size.x / 2;
			this.camera.right = this.size.x / 2;
			this.camera.top = this.size.y / 2;
			this.camera.bottom = -this.size.y / 2;
		}

		this.camera.updateProjectionMatrix();
	}

	dispose() {
		this.removeEventListeners();
	}
}
