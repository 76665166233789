import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Share from '../windows/Share';
import Default from './Default';

export default class New extends Default {
  _newsletter;

  constructor() {
    super();

    Share.init();
  }

  dispose() {
    super.dispose();
    Share.dispose();
  }
}

ControllerPage._addPage('new', New);
