import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap, Power2 } from "gsap";
import { Maths } from '../_app/cuchillo/utils/Maths';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { GetBy } from '../_app/cuchillo/core/Element';
import BezierEasing from 'bezier-easing'
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { SplitText } from 'gsap/SplitText';

class ScrollItem__TitleVideo extends VScroll_Item {
    _title;
    _media;
    _timeline;
    _chars;
    _total;
    _inc;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._call = () => this.loop();
        this.onShow = () => this.showEffect();
        this.onMove = () => {
            this.loop();
        };

        this.setupAnimation();
    }

    setupAnimation() {
        this._timeline = gsap.timeline({ paused: true });

        this._title = GetBy.class('__title', this.item)[0];
        const split = new SplitText(this._title, { type: 'lines, words, chars', tag: 'span', charsClass: "char", linesClass: "mask" });

        this._chars = split.chars;

        this._total = this._chars.length;
        this._inc = 1 / this._total;

        this._media = GetBy.class('media', this.item)[0];

        if (!isSmartphone) this._media.style.opacity = 0;
    }

    showEffect() {
        gsap.to(this._title, { opacity: 1, ease: Power2.easeIn, duration: .3 })
    }

    loop() {
        const progress = this.progressInside;
        const norm = progress;//Maths.precission(Maths.normalize(.1, 0, progress));

        let p0 = 0;
        let p1 = this._inc;

        for (let i = 0; i < this._total; i++) {
            const o = Math.max(.2, Maths.normalize(p1, p0, norm));

            this._chars[i].style.opacity = o;

            p0 = p1;
            p1 = p1 + this._inc;
        }

        this._media.style.opacity = 1;
    }
}

Scroll._registerClass('TitleVideo', ScrollItem__TitleVideo);