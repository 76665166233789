import { Vector3 } from 'three';

import WebGLSketch from '../_app/cuchillo/3D/WebGLSketch';
import { isDebug } from '../_app/cuchillo/core/Basics';

export default class Sketch {
    _scene;
    _init = false;

    static seed = 0.5;

    static init() {
        this._scene = new WebGLSketch({
            container: 'scene-kora',
            debug: isDebug,
            transparent: true,
            cameraPos: new Vector3(0, 0, 1500),
            clearColorAlpha: 0
        });

        this._init = true;
    }

    static addItem(item) {
        this._scene.scene.add(item)
    }

    static removeItem(item) {
        this._scene.scene.remove(item);
    }

    static start() {
        if (!this._init) return;

        this._scene.start();
    }

    static stop() {
        if (!this._init) return;

        this._scene.pause();
    }

    static loop() {
        if (!this._init) return;

        this._scene.loop();
    }

    static resize() {
        if (!this._init) return;

        this._scene.resize();
    }

    static getTime() {
        return this._scene.time;
    }

    static newSeed() {
        this.seed += 0.15;
    }
}
