import gsap from 'gsap';

import { isPortrait, isSmartphone } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { ScrollItem__Default } from './ScrollItem__Default';

class ScrollItem__Footer extends ScrollItem__Default {
  factor;
  article;
  _call;
  _locations;
  _slider;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.factor = isPortrait ? 0.8 : 0.25;
    this._locations = GetBy.id('LocationsSlider');

    this._slider = new SliderScroll(this._locations, {
      interaction: true,
      hasScrollbar: false,
      itemClass: ScrollItem__SliderImages__Item
    });

    this._call = () => this.loop();
    this.onShow = () => {
      this.showEffect();
      gsap.ticker.add(this._call);

    };
    this.onHide = () => {
      gsap.ticker.remove(this._call);
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  loop() {
    if (this._slider) this._slider.loop();

    const p = Maths.precission(this.progressInside, 2) * this.factor
    const trans = -100 * p;
    this._item.style.setProperty('--translation', `${trans}%`);

    if (isSmartphone) return;

    const scale = Maths.clamp(Maths.map(1 - this.progressInside, .4, .85, 1, .95), .95, 1);
    gsap.set(this._locations, {
      scaleX: scale,
      scaleY: scale
    });
  }

  dispose() {
    if (this._slider) this._slider.dispose();
    super.dispose();
  }

  resize(__w, __h) {
    super.resize(__w, __h);
    this.factor = isPortrait ? 0.8 : 0.25;
    if (this._slider) this._slider.resize();
  }
}

Scroll._registerClass('footer', ScrollItem__Footer);

class ScrollItem__SliderImages__Item extends VScroll_Item {
  hasHiddenEnabled = false;
  info;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================

  //update () {
  /* if (this.progress > .5) {
     const p = Math.max(0, Maths.normalize(1, .5, this.progress));
     this._x = this._x + ((Metrics.WIDTH * .5) * p);
   }*/
  /* super.update();
}

mouseOver () { }
mouseDown () { }
mouseUp () { }

show () {
  super.show();
}

hide () {
  super.hide();
}*/
}