import gsap, { Power3, Power2 } from 'gsap';
import { KoraButton, KoraButtons } from '../components/KoraButtons';
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import _Sidemenu from '../_app/cuchillo/layout/Sidemenu';
import { Ease } from '../_app/cuchillo/utils/Ease';

export default class Sidemenu extends _Sidemenu {
  static _tl;
  static container = GetBy.id('Sidemenu');
  static content = GetBy.class('content', this.container)[0];
  static fakeButton = GetBy.class('fake-button', this.container)[0];
  static domLocations = GetBy.class('sidemenu__location-ul', this.container)[0];
  static size = {}
  static btnToggle;

  static init() {
    this.setupSizes();
    super.init();
    this.setupAnimation();
    this.directHide__effect();

    this.btnToggle = KoraButtons.get("HeaderButtonToogle");
  }

  static setupAnimation() {
    const items = isSmartphone ? [...GetBy.class("sidemenu__link", this.container), ...GetBy.class("sidemenu__capsule", this.container)] : GetBy.class("sidemenu__capsule", this.container);
    this._tl = gsap.timeline({ paused: true });

    for (var i = 0; i < items.length; i++) {
      gsap.set(items[i], { alpha: 0 });

      this._tl.to(items[i], {
        duration: .8 + i * .1,
        alpha: 1,
        ease: Power2.easeIn,
        force3D: true
      }, 0);
    }
  }

  static show__effect() {
    gsap.killTweensOf(this.content);
    gsap.killTweensOf(this.container);
    gsap.killTweensOf(this.domLocations);
    gsap.killTweensOf(this.fakeButton);

    this.afterShow();

    this._tl.restart();

    this.btnToggle.state = KoraButton.STATE_MINI;
    this.btnToggle.changeIcon("icon-cross", .2);

    if (isSmartphone) {
      gsap.to(this.container, {
        alpha: 1,
        duration: .4,
        delay: .0,
        ease: Power3.easeInOut
      });
    } else {

      gsap.to(this.container, {
        alpha: 1,
        duration: .4,
        delay: .0,
        ease: Power3.easeInOut
      });

      this.btnToggle.container.style.pointerEvents = "all";
      gsap.to(this.btnToggle.container, {
        alpha: 1,
        duration: .4,
        delay: .2,
        ease: Power2.easeOut
      });

      gsap.to(this.fakeButton, {
        alpha: .3,
        duration: .6,
        delay: 0,
        ease: Power3.easeInOut
      });

      gsap.to(this.content, {
        x: this.positions.xShow,
        duration: .9,
        delay: 0,
        ease: Ease.EASE_CUCHILLO_IN_OUT
      });

      gsap.to(this.domLocations, {
        x: this.positions.xShow,
        duration: .9,
        delay: 0,
        ease: Ease.EASE_CUCHILLO_IN_OUT
      });
    }
  }

  static hide__effect() {
    gsap.killTweensOf(this.content);
    gsap.killTweensOf(this.container);
    gsap.killTweensOf(this.domLocations);
    gsap.killTweensOf(this.fakeButton);

    this._tl.reverse();

    this.btnToggle.state = KoraButton.STATE_MINI;
    this.btnToggle.changeIcon();

    if (isSmartphone) {
      gsap.to(this.container, {
        alpha: 0,
        duration: .3,
        delay: .1,
        ease: Power3.easeIn,
        onComplete: () => {
          this.afterHide();
        }
      });
    } else {

      this.btnToggle.container.style.pointerEvents = "none";
      gsap.to(this.btnToggle.container, {
        alpha: 0,
        duration: .4,
        delay: 0,
        ease: Power2.easeOut
      });

      gsap.to(this.fakeButton, {
        alpha: 0,
        duration: .3,
        delay: 0,
        ease: Power3.easeInOut
      });

      gsap.to(this.content, {
        x: this.positions.xHide,
        duration: .8,
        delay: 0,
        ease: Ease.EASE_CUCHILLO_IN_OUT
      });

      gsap.to(this.domLocations, {
        x: -this.positions.xHide,
        duration: .8,
        delay: 0,
        ease: Ease.EASE_CUCHILLO_IN_OUT,
        onComplete: () => {
          this.afterHide();
        }
      });
    }
  }

  static directHide__effect() {
    if (isSmartphone) {
      gsap.set(this.container, { alpha: 0 });
    } else {
      gsap.set(this.content, { x: this.positions.xHide });
      gsap.set(this.domLocations, { x: -this.positions.xHide });
      gsap.set(this.fakeButton, { alpha: 0 });
    }
  }

  static setupSizes() {
    this.sizes = {
      width: this.content.offsetWidth,
      height: this.content.offsetHeight,
    }

    this.positions = {
      xShow: 0,
      xHide: -this.sizes.width,
      xHideLocations: -this.sizes.width
    }

    this.directHide();
  }

  static resize() {
    this.setupSizes();
  }
}
