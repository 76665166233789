export const TURBULENT_FRAGMENT = `
    precision mediump float;

    #define PI 3.1415926538

    uniform float imageScale;
    uniform float progress;
    uniform float smoothness;
    uniform float scale;
    uniform float opacity;
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform vec2 resolution;

    uniform float seed;

    varying vec2 vUv;

    float scaleCenter = 0.5;

    // 
    // NOISE STUFF
    // 
    // http://byteblacksmith.com/improvements-to-the-canonical-one-liner-glsl-rand-for-opengl-es-2-0/
    float random(vec2 co) {
        highp float a = seed;
        highp float b = 78.233;
        highp float c = 43758.5453;
        highp float dt= dot(co.xy ,vec2(a,b));
        highp float sn= mod(dt,3.14);
        return fract(sin(sn) * c);
    }

    // 2D Noise based on Morgan McGuire @morgan3d
    // https://www.shadertoy.com/view/4dS3Wd
    float noise (in vec2 st) {
        vec2 i = floor(st);
        vec2 f = fract(st);

        // Four corners in 2D of a tile
        float a = random(i);
        float b = random(i + vec2(1.0, 0.0));
        float c = random(i + vec2(0.0, 1.0));
        float d = random(i + vec2(1.0, 1.0));

        // Smooth Interpolation

        // Cubic Hermine Curve.  Same as SmoothStep()
        vec2 u = f*f*(3.0-2.0*f);
        // u = smoothstep(0.,1.,f);

        // Mix 4 coorners porcentages
        return mix(a, b, u.x) +
                (c - a)* u.y * (1.0 - u.x) +
                (d - b) * u.x * u.y;
    }
    // 
    // NOISE STUFF
    // 

    void main() {
        // Applies aspect ratio to plane
        vec2 newUV = (vUv - vec2(scaleCenter)) * resolution.xy + vec2(scaleCenter);

        // Gets new centered coords for the texture and scales the image
        vec2 textureUVScale = (newUV - scaleCenter) * imageScale + scaleCenter;

        // Gets texture pixel color
        vec4 texture1Color = texture2D(texture1, textureUVScale);
        vec4 texture2Color = texture2D(texture2, textureUVScale);

        // Gets mixing values
        float n = noise(newUV * scale);
        float p = mix(-smoothness, 1.0 + smoothness, progress);
        float lower = p - smoothness;
        float higher = p + smoothness;
        
        // Gets pixel info
        float q = smoothstep(lower, higher, n);

        vec4 mixColor = mix(texture1Color, texture2Color, q);

        gl_FragColor = vec4(mixColor.rgb, mixColor.a * opacity);
    }
`;
