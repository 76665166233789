import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { ScrollItem__Default } from './ScrollItem__Default';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import { Maths } from '../_app/cuchillo/utils/Maths';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { GetBy } from '../_app/cuchillo/core/Element';
import BezierEasing from 'bezier-easing'
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import LoaderController from '../_app/cuchillo/loaders/LoaderController';

class ScrollItem__SliderCircular extends ScrollItem__Default {
    _call;
    _slider;
    _loader;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._slider = new SliderScroll(__link, {
            itemClass: ScrollItem__SliderCircular__Item,
            interaction: true,
            hasScrollbar: true,
            endOutside: true,
        });

        this._call = () => this.loop();
        this.onShow = () => this.showEffect();
        this.onHide = () => this.hideEffect();

        if (LoaderController.get('HorizontalScrollLoader')) LoaderController.get('HorizontalScrollLoader').initBackground();
    }

    showEffect() {
        gsap.ticker.add(this._call);
        super.showEffect();
    }

    hideEffect() {
        gsap.ticker.remove(this._call);
        super.hideEffect();
    }

    resize(w, h) {
        super.resize(w, h);
        if (this._slider) {
            this._slider.resize();
            LoaderController.get('HorizontalScrollLoader').resize();
        }
    }

    loop() {
        if (this._slider) {
            this._slider.loop();
            LoaderController.get('HorizontalScrollLoader').loop(this._slider.x);
        }
    }

    dispose() {
        if (this._slider) this._slider.dispose();
        super.dispose();
    }
}

class ScrollItem__SliderCircular__Item extends VScroll_Item {
    static easing = BezierEasing(0.12, 0.67, 0.36, 0.93);

    hasMove = false;
    hasHiddenEnabled = false;
    limit = isSmartphone ? 3 : 2;
    _domProgress;
    _domImage;
    _domImg;
    _domFigure;
    isEmpty = false;
    options = {
        default: {
            angle: 60,
            max: 1,
            min: .6,
        },
        desktop: {
            angle: 60,
            max: 1,
            min: .6,
            offset: 0,
        },
        smartphone: {
            angle: 60,
            max: 1,
            min: .6,
            offset: 0
        }
    }

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
        this._domImage = GetBy.class("image", this.item)[0];
        this._domImg = GetBy.selector("img", this.item)[0];
        this._domFigure = GetBy.class("figure", this.item)[0];
        this.isEmpty = !this._domImage;

        this.setupOptions();
    }

    setupOptions() {
        this.options.desktop.angle = this.item.getAttribute("data-angle") !== null ? Number(this.item.getAttribute("data-angle")) : this.options.desktop.angle;
        this.options.desktop.max = this.item.getAttribute("data-scale-max") !== null ? Number(this.item.getAttribute("data-scale-max")) : this.options.desktop.max;
        this.options.desktop.min = this.item.getAttribute("data-scale-min") !== null ? Number(this.item.getAttribute("data-scale-min")) : this.options.desktop.min;
        this.options.smartphone.angle = this.item.getAttribute("data-angle-sm") !== null ? Number(this.item.getAttribute("data-angle-sm")) : this.options.desktop.angle;
        this.options.smartphone.max = this.item.getAttribute("data-scale-max-sm") !== null ? Number(this.item.getAttribute("data-scale-max-sm")) : this.options.desktop.max;
        this.options.smartphone.min = this.item.getAttribute("data-scale-min-sm") !== null ? Number(this.item.getAttribute("data-scale-min-sm")) : this.options.desktop.min;


        if (isSmartphone) {
            this.options.default = this.options.smartphone;
        } else {
            this.options.default = this.options.desktop;
        }
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    mouseOver() { }
    mouseDown() { }
    mouseUp() { }
    drawHook() {
        if (!this.isEmpty) {
            const PROGRESS = this.progressInside - 1; //-1 para que el primero comience en el centro
            let scale;

            //const zIndex = Math.floor(Maths.lerp(100, 0, Math.abs(PROGRESS)))

            if (PROGRESS < -this.limit || PROGRESS > this.limit) {
                this._domImg.classList.remove('--viewport');
                this.item.style.opacity = 0;
                this.item.style.pointerEvents = "none";
            } else {
                this._domImg.classList.add('--viewport');

                const rot2 = Math.max(-200, Math.min(200, this._scroller.speed)) * -.01;

                if (PROGRESS < 0) {
                    scale = Math.max(Maths.lerp(this.options.default.max, this.options.default.min, -PROGRESS));
                } else {
                    scale = Math.max(Maths.lerp(this.options.default.max, this.options.default.min, PROGRESS));
                }

                const rot = Maths.lerp(0, -this.options.default.angle, PROGRESS);
                this._domImage.style[CSS.transform] = CSS.rotate3D(0, 0, 1, rot);
                //this._domImage.style["z-index"] = `${zIndex}`;
                this._domFigure.style[CSS.transform] = CSS.scale3D(scale, scale, scale) + " " + CSS.rotate3D(0, 1, 0, rot2);
                this.item.style.opacity = 1;
                this.item.style.pointerEvents = "all";
            }
        }
    }

    show() {
        super.show();
    }

    hide() {
        super.hide();
    }

    resize(w, h) {
        super.resize(w, h);
    }
}

Scroll._registerClass('SliderCircular', ScrollItem__SliderCircular);