import { TextureLoader } from 'three';

import WebGLObject from './WebGLObject';

export default class Image extends WebGLObject {
    texture;
    _textureLoader;

    constructor(opts = {}) {
        super(opts);
        this._textureLoader = new TextureLoader();
    }

    init() {
        super.init();
        this.loadTexture();
        this.resize();
    }

    loadTexture() {
        const image = this._target.dataset.src;

        this._textureLoader.load(
            image,
            texture => {
                this.texture = texture;
                this.material.uniforms.texture1.value = texture;
            },
            undefined,
            // onError callback
            function (err) {
                console.error('An error happened.', err);
            });

        const mask = this._target.dataset.mask;
        if (mask) {
            this._textureLoader.load(
                mask + WEBP_EXTENSION,
                texture => {
                    this.material.uniforms.mask.value = texture;
                },
                undefined,
                // onError callback
                function (err) {
                    console.error('An error happened.', err);
                });
        }
    }

    calculateResolution() {
        const width = this.size.x;
        const height = this.size.y;

        const planeAspect = this.mesh.scale.y / this.mesh.scale.x;
        let a1;
        let a2;

        if (height / width > planeAspect) {
            const h = (width / height) * planeAspect;
            // Canvas more horizontal than image
            a1 = 1;
            a2 = h;
        } else {
            // Canvas more vertical than image
            a1 = height / width / planeAspect;
            a2 = 1;
        }

        return {
            x: a1,
            y: a2
        };
    }

    resize() {
        const { x, y, width, height } = this._target.getBoundingClientRect();

        const position = this.domPositionTo3D(x, y);
        this.position.x = position.x + width * .5;
        this.position.y = position.y - height * .5;
        this.pos.x = this.position.x;
        this.pos.y = this.position.y;

        super.resize(width, height);

        this.material.uniforms.resolution.value = this.calculateResolution();
    }
}
