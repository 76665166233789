import gsap, { Power1, Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { ScrollItem__Default } from './ScrollItem__Default';

class ScrollItem__BillboardNew extends ScrollItem__Default {
  _bg;
  // _title;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.onShow = () => this.showEffect();

    // this._bg = GetBy.class('media-holder', __link)[0];
    // gsap.set(this._bg, {
    //   opacity: 0,
    //   scaleX: 1.05,
    //   scaleY: 1.05
    // });

    // this.onShow = () => {
    //   let delay = 0;
    //   gsap.to(this._bg, {
    //     opacity: 1,
    //     scaleX: 1,
    //     scaleY: 1,
    //     duration: 1.5,
    //     delay,
    //     ease: Power2.easeOut
    //   });
    // };
    // this.onHide = () => { };
    // this.onMove = () => {
    //   let opacity = 1;
    //   if (this.progress >= .5) opacity = Maths.clamp(Maths.map(this.progress, .5, .9, 1, 0), 0, 1);

    //   gsap.set(this._bg, { opacity });
    // }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('billboard-new', ScrollItem__BillboardNew);
