import { PlaneGeometry, ShaderMaterial, DoubleSide } from 'three';

import CanvasColorTexture from '../_app/cuchillo/3D/CanvasColorTexture';
import Image from '../_app/cuchillo/3D/Image';
import Video from '../_app/cuchillo/3D/Video';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { TURBULENT_FRAGMENT } from '../_app/cuchillo/shaders/turbulent-displacement-fragment';
import { IMAGE_VERTEX } from '../_app/cuchillo/shaders/image-vertex';
import Sketch from './Sketch';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

export const SETTINGS = {
    opacity: 1,
    imageScale: 1,
    maskScale: 1,
    progress: 1,
    smoothness: .15,
    scale: 3,
    intensity: 25,
    seed: .1,
}

export default class HighlightTextController {
    bg;

    constructor() { }

    setupBG(target, src) {
        const geometry = new PlaneGeometry();
        const material = new ShaderMaterial({
            uniforms: {
                texture1: { type: 't', value: new CanvasColorTexture().texture },
                texture2: { type: 't', value: new CanvasColorTexture('transparent').texture },
                mask: { type: 't', value: new CanvasColorTexture().texture },
                progress: { type: 'f', value: 1 },
                smoothness: { type: 'f', value: SETTINGS.smoothness },
                intensity: { type: 'f', value: SETTINGS.intensity },
                scale: { type: 'f', value: SETTINGS.scale },
                opacity: { type: 'f', value: SETTINGS.opacity },
                imageScale: { type: 'f', value: 1 },
                seed: { type: 'f', value: SETTINGS.seed },
                resolution: {
                    type: 'v2',
                    value: { x: 1, y: 1 }
                }
            },
            fragmentShader: TURBULENT_FRAGMENT,
            vertexShader: IMAGE_VERTEX,
            transparent: true,
            side: DoubleSide
        });

        if (target.classList.contains('--video')) {
            this.bg = new Video({
                geometry,
                material,
                target,
                src
            });
        } else {
            this.bg = new Image({
                geometry,
                material,
                target,
                src
            });
        }
        this.bg.init();
        this.bg.active = true;
        this.bg.position.z = 0;

        Sketch.addItem(this.bg);
    }

    start() {
        // if (isDebug) DebugPane.init();
    }

    stop() {
        // if (isDebug) DebugPane.dispose();
    }

    update(opts) {
        const { progress, y } = opts;

        //BG
        let alpha = 0;
        if (progress < .5) {
            alpha = Maths.clamp(Maths.map(progress, .1, .25, 0, 1), 0, 1);
        } else {
            alpha = Maths.clamp(Maths.map(progress, .75, .9, 1, 0), 0, 1);
        }

        let normScale = Maths.normalize(1, .5, progress);
        let scale = Maths.lerp(.8, 1, normScale);

        this.bg.update();

        this.bg.material.uniforms.seed.value = Sketch.seed;
        this.bg.material.uniforms.progress.value = alpha;
        this.bg.material.uniforms.imageScale.value = scale;

        this.bg.position.y = 0;
    }

    resize() {
        if (isSmartphone) return;

        if (this.bg) this.bg.resize();
    }

    dispose() {
        Sketch.removeItem(this.bg);
        this.bg.dispose();

        // if (isDebug) DebugPane.dispose();
    }
}
