import gsap, { Power2 } from 'gsap';
import { Accessibility } from '../_app/cuchillo/core/Accessibility';

import { Basics } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import Keyboard, { KEYS } from '../_app/cuchillo/core/Keyboard';

export default class Share {
  _triggers;
  _state;
  _copyLink;

  static STATE_OPEN = 'OPEN';
  static STATE_CLOSE = 'CLOSE';
  static container = GetBy.id('Share');
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  static init() {
    this.directHide();

    this._triggers = [...GetBy.class('__share')];

    this._toggle = () => Share.toggle();
    this._triggers.map(t => {
      t.addEventListener(Basics.clickEvent, this._toggle);
    });

    const urlFB = 'https://www.facebook.com/sharer/sharer.php?u=***URL***';
    const urlTwitter = 'http://twitter.com/share?url=***URL***';

    GetBy.class('__fb', Share.container)[0].setAttribute('href', encodeURI(urlFB.split('***URL***').join(window.location.href)));
    GetBy.class('__tw', Share.container)[0].setAttribute('href', encodeURI(urlTwitter.split('***URL***').join(window.location.href)));
    GetBy.class('__link', Share.container)[0].textContent = window.location.href;

    this._copyLink = document.getElementsByClassName('__copyLink')[0];
    this._copy = (e) => {
      e.preventDefault();
      try {
        const el = document.createElement('textarea');
        el.value = document.getElementsByClassName('__link')[0].textContent;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        gsap.to(this._copyLink, { scale: 1.2, duration: .05, force3D: true });
        gsap.to(this._copyLink, { scale: 1.0, duration: .05, delay: .05, force3D: true });
      } catch (err) { }
    }
    this._copyLink.addEventListener(Basics.clickEvent, this._copy);
  }

  static toggle() {
    try {
      shareData.url = window.location.href;
      navigator.share(shareData)
    } catch(err) {
      if (this._state === Share.STATE_OPEN) this.hide();
      else this.show();
    }
  }

  //SHOW
  static show(__d = 0) {
    this._state = Share.STATE_OPEN;
    this.show__effect();

    Accessibility.trap(Share.container);
    Keyboard.add(KEYS.ESC, 'CookiesESC', () => { Share.hide(); });
  }

  static show__effect(__d = 0) {
    this.container.setAttribute('aria-expanded', 'true');
    gsap.to(this.container, {
      y: 0,
      duration: .8,
      ease: Power2.easeInOut
    });
    gsap.to(this.container, {
      opacity: 1,
      duration: .4,
      delay: .4,
      ease: Power2.easeInOut
    });
  }

  //HIDE
  static directHide() {
    gsap.set(this.container, { opacity: 0, y: '100%' });
    this.container.setAttribute('aria-expanded', 'false');
  }

  static hide(__d = 0) {
    this._state = Share.STATE_CLOSE;
    this.hide__effect();
  }

  static hide__effect(__d = 0) {
    gsap.to(this.container, {
      opacity: 0,
      duration: .4,
      ease: Power2.easeInOut
    });
    gsap.to(this.container, {
      opacity: 0,
      y: '100%',
      duration: .8,
      ease: Power2.easeInOut,
      onComplete: () => {
        this.container.setAttribute('aria-expanded', 'false');
      }
    });
  }

  static dispose() {
    this.hide();

    this._triggers.map(t => {
      t.removeEventListener(Basics.clickEvent, this._toggle);
    });

    this._copyLink.removeEventListener(Basics.clickEvent, this._copy);

    Accessibility.removeTrap();
    Keyboard.remove(KEYS.ESC, 'CookiesESC');
    if (document.body.classList.contains('__accessible')) {
      GetBy.tag('a', GetBy.id('Gotomain'))[0].focus();
    }
  }
}
