import { Vector3 } from 'three';

import Image from '../_app/cuchillo/3D/Image';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class PortalImage extends Image {
    mouse = new Vector3();

    constructor(opts = {}) {
        super(opts);
    }

    loadTexture() {
        const image = this._target.dataset.src + WEBP_EXTENSION;
        this._textureLoader.load(
            image,
            texture => {
                this.texture = texture;
                this.material.uniforms.texture1.value = texture;
            },
            undefined,
            // onError callback
            function (err) {
                console.error('An error happened.', err);
            });

        const text = this._target.dataset.text;
        this._textureLoader.load(
            text,
            texture => {
                // this.texture = texture;
                this.material.uniforms.texture2.value = texture;
            },
            undefined,
            // onError callback
            function (err) {
                console.error('An error happened.', err);
            });

        const mask = this._target.dataset.mask;
        if (mask) {
            this._textureLoader.load(
                mask,
                texture => {
                    this.material.uniforms.mask.value = texture;
                },
                undefined,
                // onError callback
                function (err) {
                    console.error('An error happened.', err);
                });
        }
    }

    update() {
        if (!this.active) return;

        this.position.x = Maths.lerp(this.position.x, this.pos.x - this.mouse.x, this.opts.xFactor);
        this.position.y = Maths.lerp(this.position.y, this.pos.y - this.mouse.y, this.opts.yFactor);
        this.position.z = Maths.lerp(this.position.z, this.pos.z + this.mouse.z, this.opts.zFactor);

        this.rotation.x = Maths.lerp(this.rotation.x, this.rot.x, this.opts.rotFactor);
        this.rotation.y = Maths.lerp(this.rotation.y, this.rot.y, this.opts.rotFactor);
        this.rotation.z = Maths.lerp(this.rotation.z, this.rot.z, this.opts.rotFactor);
    }
}
