import { VideoTexture } from 'three';

import { GetBy } from '../_app/cuchillo/core/Element';
import PortalImage from './PortalImage';

export default class PortalVideo extends PortalImage {
    isVideoLoaded = false;

    constructor(opts = {}) {
        super(opts);
    }

    loadTexture() {
        const image = this._target.dataset.poster;
        this._textureLoader.load(
            image,
            texture => {
                if (this.isVideoLoaded) return;
                this.texture = texture;
                this.material.uniforms.texture1.value = texture;
            },
            undefined,
            // onError callback
            function (err) {
                console.error('An error happened.', err);
            });

        const video = GetBy.tag('video', this._target)[0];
        const _play = () => {
            this.isVideoLoaded = true;
            video.removeEventListener('play', _play);

            this.texture = new VideoTexture(video);
            this.material.uniforms.texture1.value = this.texture;
        }
        video.addEventListener('play', _play);

        const text = this._target.dataset.text;
        this._textureLoader.load(
            text,
            texture => {
                // this.texture = texture;
                this.material.uniforms.texture2.value = texture;
            },
            undefined,
            // onError callback
            function (err) {
                console.error('An error happened.', err);
            });

        const mask = this._target.dataset.mask;
        if (mask) {
            this._textureLoader.load(
                mask,
                texture => {
                    this.material.uniforms.mask.value = texture;
                },
                undefined,
                // onError callback
                function (err) {
                    console.error('An error happened.', err);
                });
        }
    }
}
