import gsap, { Power2, Power3 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';

export class ScrollItem__Default extends VScroll_Item {
  _timeline;
  _headline;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);
    this.setUpDefault();
    this.onShow = () => this.showEffect();
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  setUpDefault() {
    this._timeline = gsap.timeline({ paused: true });

    const split = new SplitText(GetBy.class('__title', this.item)[0], { type: 'lines', tag: 'span' });
    split.lines.map(line => {
      const holder = document.createElement("span");
      line.parentNode.append(holder);
      holder.append(line);
      holder.classList.add("mask");
    })

    const lines = [...split.lines];
    const texts = [...GetBy.class("__text", this.item)];

    let delay = .4;
    for (var i = 0; i < lines.length; i++) {
      gsap.set(lines[i], { alpha: 1, y: "102%" });

      delay = i * .15;

      this._timeline.to(lines[i], {
        y: 0,
        duration: 1.1,
        ease: Power3.easeOut,
        force3D: true,
      }, delay);

      this._timeline.to(lines[i], {
        alpha: 1,
        duration: .1,
        ease: Power2.easeIn,
        force3D: true,
      }, delay + .1);
    }

    delay += .3;
    for (var i = 0; i < texts.length; i++) {
      gsap.set(texts[i], { alpha: 0 });
      this._timeline.to(texts[i], {
        alpha: 1,
        duration: 1,
        ease: Power3.easeInOut,
        force3D: true,
      }, delay);

      delay = i * .1;
    }
  }

  showEffect() {
    this._timeline.play();
  }

  hideEffect() { }
}

Scroll._registerClass('default', ScrollItem__Default);
