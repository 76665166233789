import { PlaneGeometry, ShaderMaterial, DoubleSide } from 'three';
import gsap, { Power2, Power3, Linear, Expo } from 'gsap';
import { SlowMo } from "gsap/EasePack";

import { Interaction } from '../_app/cuchillo/core/Interaction';
// import { isDebug } from '../_app/cuchillo/core/Basics';
import CanvasColorTexture from '../_app/cuchillo/3D/CanvasColorTexture';
import { IMAGE_VERTEX } from '../_app/cuchillo/shaders/image-vertex';
import Image from '../_app/cuchillo/3D/Image';
import PortalVideo from './PortalVideo';
import PortalImage from './PortalImage';
// import DebugPane from './DebugPaneBillboard';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { PORTAL_FRAGMENT } from '../shaders/portal-fragment';
import { TURBULENT_FRAGMENT } from '../_app/cuchillo/shaders/turbulent-displacement-fragment';
import Sketch from './Sketch';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';

gsap.registerPlugin(SlowMo);

export const SETTINGS = {
    opacity: 0,
    imageScale: .9,
    maskScale: 1,
    progress: 0,
    smoothnessPortal: .1,
    smoothness: .15,
    scale: 3,
    scalePortal: 20,
    intensity: 25,
    mouseX: 15,
    mouseY: 10,
    mouseZ: 80,
    rotX: 8,
    rotY: 4,
    seed: .1,
    darken: .2
}

export default class BillboardController {
    planes = [];
    texts = [];
    bg;
    zIndex = [0, .5, 20, 25];
    mouse = { x: 0, y: 0 };

    isEnabled = false;

    constructor() { }

    setupBG(target, src) {
        const geometry = new PlaneGeometry();
        const material = new ShaderMaterial({
            uniforms: {
                texture1: { type: 't', value: new CanvasColorTexture().texture },
                texture2: { type: 't', value: new CanvasColorTexture('transparent').texture },
                mask: { type: 't', value: new CanvasColorTexture().texture },
                progress: { type: 'f', value: 1 },
                smoothness: { type: 'f', value: SETTINGS.smoothness },
                intensity: { type: 'f', value: SETTINGS.intensity },
                scale: { type: 'f', value: SETTINGS.scale },
                opacity: { type: 'f', value: SETTINGS.opacity },
                imageScale: { type: 'f', value: 1 },
                seed: { type: 'f', value: SETTINGS.seed },
                resolution: {
                    type: 'v2',
                    value: { x: 1, y: 1 }
                }
            },
            fragmentShader: TURBULENT_FRAGMENT,
            vertexShader: IMAGE_VERTEX,
            transparent: true,
            side: DoubleSide
        });

        this.bg = new Image({
            geometry,
            material,
            target,
            src
        });
        this.bg.init();
        this.bg.active = true;
        this.bg.position.z = this.zIndex[0];

        Sketch.addItem(this.bg);
    }

    setupPortals(items) {
        const geometry = new PlaneGeometry();
        const factor = [
            { x: .1, y: 0.8, z: .1 },
            { x: .1, y: 0.6, z: .06 },
            { x: .1, y: 0.7, z: .08 }
        ]

        let contPortals = 0;
        let contPortalsText = 0;
        let plane;

        for (let i = 0; i < items.length; i++) {
            const material = new ShaderMaterial({
                uniforms: {
                    texture1: { type: 't', value: new CanvasColorTexture().texture },
                    texture2: { type: 't', value: new CanvasColorTexture().texture },
                    texture3: { type: 't', value: new CanvasColorTexture('transparent').texture },
                    texture4: { type: 't', value: new CanvasColorTexture('#000000').texture },
                    mask: { type: 't', value: new CanvasColorTexture().texture },
                    opacity: { type: 'f', value: 0 },
                    imageScale: { type: 'f', value: SETTINGS.imageScale },
                    maskScale: { type: 'f', value: SETTINGS.maskScale },
                    progress: { type: 'f', value: SETTINGS.progress },
                    smoothness: { type: 'f', value: SETTINGS.smoothness },
                    intensity: { type: 'f', value: SETTINGS.intensity },
                    scale: { type: 'f', value: SETTINGS.scalePortal },
                    seed: { type: 'f', value: SETTINGS.seed },
                    darken: { type: 'f', value: SETTINGS.darken },
                    resolution: {
                        type: 'v2',
                        value: { x: 1, y: 1 }
                    },
                    mouse: {
                        type: 'v2',
                        value: { x: 0, y: 0 }
                    }
                },
                fragmentShader: PORTAL_FRAGMENT,
                vertexShader: IMAGE_VERTEX,
                transparent: true,
                side: DoubleSide
            });

            if (!items[i].classList.contains("--text") && items[i].classList.contains("--video")) {
                plane = new PortalVideo({
                    geometry,
                    material,
                    target: items[i],
                    xFactor: factor[contPortals].x,
                    yFactor: factor[contPortals].y,
                    zFactor: factor[contPortals].z,
                    rotFactor: .01
                });

                contPortals++;
                this.planes.push(plane);

            } else if (!items[i].classList.contains("--text") && items[i].classList.contains("--image")) {
                plane = new PortalImage({
                    geometry,
                    material,
                    target: items[i],
                    xFactor: factor[contPortals].x,
                    yFactor: factor[contPortals].y,
                    zFactor: factor[contPortals].z
                });

                contPortals++;
                this.planes.push(plane);

            } else {
                plane = new Image({
                    geometry,
                    material,
                    target: items[i],
                    xFactor: factor[contPortalsText].x,
                    yFactor: factor[contPortalsText].y,
                    zFactor: factor[contPortalsText].z
                });
                plane.material.uniforms.darken.value = 0;
                plane.material.uniforms.imageScale.value = 1;
                this.texts.push(plane);
            }

            plane.init();
            plane.active = true;

            Sketch.addItem(plane);
        }

        // const temp = this.planes[0];
        // this.planes[0] = this.planes[1];
        // this.planes[1] = temp;
    }

    showBillboard(__call) {
        if (this.isEnabled) return;

        Scroll.stop();

        //PORTALS POSITION
        this.planes[0].position.z = 1000;
        this.planes[1].position.z = 3000;
        this.planes[2].position.z = 1500;

        this.planes[0].position.x = -Metrics.WIDTH * .5;
        this.planes[2].position.x = Metrics.WIDTH * .5;

        //SHOW TEXT
        this.texts[0].position.z = 300;
        this.texts[1].position.z = 300;
        this.texts[2].position.z = 300;
        gsap.to(this.texts[0].material.uniforms.opacity, { value: 1, duration: .4, ease: Power2.easeIn, delay: 0 });
        gsap.to(this.texts[1].material.uniforms.opacity, { value: 1, duration: .4, ease: Power2.easeIn, delay: .1 });
        gsap.to(this.texts[2].material.uniforms.opacity, { value: 1, duration: .4, ease: Power2.easeIn, delay: .2 });

        //SHOWBG
        // const normScale = Maths.normalize(1, .5, 0.3333333333333333);
        const normScale = Maths.normalize(1, .5, 0.5);
        const scale = Maths.lerp(.8, 1, normScale);
        let delay = .8;
        this.bg.material.uniforms.imageScale.value = .4;
        gsap.to(this.bg.material.uniforms.opacity, { value: 1, duration: .4, ease: Power2.easeIn, delay: delay });
        gsap.to(this.bg.material.uniforms.progress, { value: 1, duration: 2, ease: Power3.easeOut, delay: delay });
        gsap.to(this.bg.material.uniforms.imageScale, {
            value: scale,
            duration: 1.2,
            ease: Expo.easeOut,
            delay: delay - .1,
            onComplete: () => {
                this.isEnabled = true;
                if (__call) __call();
                Sketch.newSeed();
            }
        });

        delay += 1.25;
        gsap.to(this.texts[0].position, { z: this.zIndex[1], duration: delay + .3, ease: Linear.easeNone, delay: 0 });
        gsap.to(this.texts[1].position, { z: this.zIndex[1], duration: delay + .4, ease: Linear.easeNone, delay: .1 });
        gsap.to(this.texts[2].position, { z: this.zIndex[1], duration: delay + .5, ease: Linear.easeNone, delay: .2 });

        gsap.to(this.texts[0].material.uniforms.opacity, { value: 0, duration: .4, ease: Power2.easeInOut, delay: delay });
        gsap.to(this.texts[1].material.uniforms.opacity, { value: 0, duration: .5, ease: Power2.easeInOut, delay: delay });
        gsap.to(this.texts[2].material.uniforms.opacity, { value: 0, duration: .6, ease: Power2.easeInOut, delay: delay });

        //BURN PORTALS
        delay += .5;
        gsap.to(this.planes[0].material.uniforms.progress, { value: 1, duration: 2, ease: Power3.easeOut, delay: delay });
        gsap.to(this.planes[1].material.uniforms.progress, { value: 1, duration: 2, ease: Power3.easeOut, delay: delay + .2 });
        gsap.to(this.planes[2].material.uniforms.progress, { value: 1, duration: 2, ease: Power3.easeOut, delay: delay + .4 });
    }

    start() {
        // if (isDebug) DebugPane.init();
    }

    stop() {
        // if (isDebug) DebugPane.dispose();
    }

    update(opts) {
        if (!this.isEnabled) return;

        const { progress, y } = opts;

        //BG
        let normAlpha = Maths.normalize(1, .9, progress);
        let alpha = Maths.clamp(Maths.lerp(1, 0, normAlpha), 0, 1);
        let normScale = Maths.normalize(1, .5, progress);
        let scale = Maths.lerp(.8, 1, normScale);

        this.bg.update();

        this.bg.material.uniforms.opacity.value = alpha;
        this.bg.material.uniforms.progress.value = alpha;
        this.bg.material.uniforms.imageScale.value = scale;
        this.bg.material.uniforms.seed.value = Sketch.seed;

        this.bg.position.y = 0;

        // MOUSE 
        const center = {
            x: Interaction.positions.mouse.x - window.innerWidth / 2,
            y: Interaction.positions.mouse.y - window.innerHeight / 2
        };
        const mx = Maths.map(center.x, - window.innerWidth / 2, window.innerWidth / 2, -SETTINGS.mouseX, SETTINGS.mouseX);
        const my = - Maths.map(center.y, - window.innerHeight / 2, window.innerHeight / 2, -SETTINGS.mouseY, SETTINGS.mouseY);
        this.mouse.x = Maths.lerp(this.mouse.x, mx, .015);
        this.mouse.y = Maths.lerp(this.mouse.y, my, .015);

        const rx = Maths.map(center.x, - window.innerWidth / 2, window.innerWidth / 2, -SETTINGS.rotX, SETTINGS.rotX);
        const ry = - Maths.map(center.y, - window.innerHeight / 2, window.innerHeight / 2, -SETTINGS.rotY, SETTINGS.rotY);

        //PORTALS
        const pos = [
            { x: y * .5, y: -y * .4, z: -y * 1 },
            { x: 0, y: -y * .6, z: -y * 2 },
            { x: y * .1, y: -y * .2, z: -y * .5 },
        ];

        normAlpha = Maths.normalize(.9, .8, progress);
        alpha = Maths.lerp(1, 0, normAlpha);
        scale = Math.min(SETTINGS.imageScale, SETTINGS.imageScale + y / 5000);

        for (let i = 0; i < this.planes.length; i++) {
            const plane = this.planes[i];

            plane.material.uniforms.seed.value = Sketch.seed;

            plane.material.uniforms.opacity.value = Math.min(1, alpha);
            plane.material.uniforms.imageScale.value = scale;

            plane.material.uniforms.mouse.value.x = this.mouse.x * 0.008;
            plane.material.uniforms.mouse.value.y = this.mouse.y * 0.008;

            plane.material.uniforms.darken.value = SETTINGS.darken;

            plane.mouse.x = this.mouse.x;
            plane.mouse.y = this.mouse.y;
            const dist = Math.abs(center.x - plane.pos.x);
            const mz = Math.max(Maths.map(dist, 0, window.innerWidth / 2, this.zIndex[2], SETTINGS.mouseZ), this.zIndex[2]);
            plane.mouse.z = Maths.lerp(plane.mouse.z, mz, .008);

            plane.pos.y = pos[i].y;
            plane.pos.z = this.zIndex[2] + pos[i].z;

            plane.rot.x = - Maths.toRadians(ry);
            plane.rot.y = Maths.toRadians(rx);

            plane.update();
        }

        //TEXTS
        for (let i = 0; i < this.texts.length; i++) {
            const plane = this.texts[i];
            plane.update();
        }
    }

    resize() {
        if (this.bg) this.bg.resize();

        for (let i = 0; i < this.planes.length; i++) {
            this.planes[i].resize();
        }

        for (let i = 0; i < this.texts.length; i++) {
            this.texts[i].resize();
        }
    }

    dispose() {
        Sketch.removeItem(this.bg);
        this.bg.dispose();

        for (let i = 0; i < this.planes.length; i++) {
            Sketch.removeItem(this.planes[i]);
            this.planes[i].dispose();

            Sketch.removeItem(this.texts[i]);
            this.texts[i].dispose();
        }
    }
}
