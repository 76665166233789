import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { ScrollItem__Default } from './ScrollItem__Default';

class ScrollItem__ParallaxCards extends ScrollItem__Default {
  items = [];
  text;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    [...GetBy.class('__card', __link)].map(item => {
      this.items.push(new Parallax__Card(item));
    });

    this.onShow = () => this.showEffect();
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================

  showEffect() {
    super.showEffect();

    if (isSmartphone) return;

    setTimeout(() => {
      Scroll.goto(Metrics.HEIGHT * .3);
    }, 500);
  }

  resize(__w, __h) {
    this.items.map(item => item.resize());
    super.resize(__w, __h);
  }

  // dispose() {
  //   this.items.map(item => item.dispose());
  //   super.dispose();
  // }
}

Scroll._registerClass('parallax-cards', ScrollItem__ParallaxCards);

class Parallax__Card {
  container;
  wrapper;
  content;
  card;
  // title;
  // titleAux;
  // icon;
  // thumb;

  constructor(container) {
    this.container = container;
    this.card = GetBy.class('__btn', container)[0];
    this.content = GetBy.class('__content', container)[0];
    // this.title = GetBy.selector('.title > span:first-child', container);
    // this.titleAux = GetBy.selector('.title > span:last-child', container);
    // this.icon = GetBy.class('__icon', container);
    // this.thumb = GetBy.class('__thumb', container)[0];
    this.wrapper = container.firstElementChild;

    // this._toggle = () => this.toggle();
    // this._hoverIn = () => this.hoverIn();
    // this._hoverOut = () => this.hoverOut();
    // this.wrapper.addEventListener(Basics.clickEvent, this._toggle);
    // if (!isTouch) {
    //   this.wrapper.addEventListener('mouseover', this._hoverIn);
    //   this.wrapper.addEventListener('mouseout', this._hoverOut);
    // }

    this.resize();
  }

  hover() { }

  // toggle() {
  //   this.killAll();

  //   if (this.wrapper.classList.contains('--open')) {
  //     this.wrapper.classList.remove('--open');
  //     gsap.to(this.content, { opacity: 0, duration: .2, ease: Power2.easeOut });
  //     gsap.to(this.icon, { rotateZ: 0, duration: .5, ease: Power2.easeOut });

  //     if (!isTouch) {
  //       gsap.to(this.thumb, { scale: 1.0, duration: .5, ease: Back.easeOut, force3D: true });
  //       gsap.set(this.titleAux, { opacity: 0, y: 0 });
  //       gsap.set(this.title, { opacity: 1 });
  //       gsap.to(this.title, { y: 0, duration: .4, ease: Power2.easeOut });
  //     }
  //   } else {
  //     this.wrapper.classList.add('--open');
  //     gsap.to(this.content, { opacity: 1, duration: .4, delay: .7, ease: Power2.easeOut });
  //     gsap.to(this.icon, { rotateZ: '135deg', duration: .7, delay: .2, ease: Power2.easeOut });

  //     if (!isTouch) {
  //       gsap.to(this.titleAux, { y: '-100%', duration: .3, ease: Power2.easeOut });
  //     }
  //   }

  //   gsap.to(this.wrapper, { scale: 1.04, duration: .4, force3D: true });
  //   gsap.to(this.wrapper, { scale: 1.0, duration: .4, delay: .1, force3D: true });
  // }

  resize() {
    const { height: hCard, width: wCard, } = this.card.getBoundingClientRect();
    const { height: hContent, width: wContent, } = this.content.parentNode.getBoundingClientRect();

    this.wrapper.style.setProperty('--height-card', hCard + 'px');
    this.wrapper.style.setProperty('--width-card', wCard + 'px');
    this.wrapper.style.setProperty('--height-content', hContent + 'px');
    this.wrapper.style.setProperty('--width-content', wContent + 'px');
  }

  // hoverIn() {
  //   gsap.to(this.wrapper, { scale: 1.02, duration: .4, force3D: true });

  //   if (!this.wrapper.classList.contains('--open')) {
  //     gsap.set(this.titleAux, { opacity: 1, y: 0 });
  //     gsap.set(this.title, { opacity: 0, y: '-100%' });
  //     gsap.to(this.titleAux, { y: '-100%', duration: .6, delay: .1, ease: Power2.easeOut });
  //     gsap.to(this.icon, { rotateZ: '90deg', duration: .5, delay: .2, ease: Power2.easeOut });
  //     gsap.to(this.thumb, { scale: 1.1, duration: .5, ease: Back.easeOut, force3D: true });
  //   }
  // }

  // hoverOut() {
  //   gsap.to(this.wrapper, { scale: 1.0, duration: .3, force3D: true });

  //   if (!this.wrapper.classList.contains('--open')) {
  //     gsap.set(this.titleAux, { opacity: 0, y: 0 });
  //     gsap.set(this.title, { opacity: 1 });
  //     gsap.to(this.title, { y: 0, duration: .4, ease: Power2.easeOut });
  //     gsap.to(this.icon, { rotateZ: 0, duration: .3, ease: Power2.easeOut });
  //     gsap.to(this.thumb, { scale: 1.0, duration: .5, ease: Back.easeOut, force3D: true });
  //   }
  // }

  // killAll() {
  //   gsap.killTweensOf(this.card);
  //   gsap.killTweensOf(this.title);
  //   gsap.killTweensOf(this.titleAux);
  //   gsap.killTweensOf(this.wrapper);
  // }

  // dispose() {
  // this.wrapper.removeEventListener(Basics.clickEvent, this._toggle);
  // if (!isTouch) {
  //   this.wrapper.removeEventListener('mouseover', this._hoverIn);
  //   this.wrapper.removeEventListener('mouseout', this._hoverOut);
  // }
  // }
}