import gsap from "gsap";
import CustomEase from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase);

const Ease = {
  EASE_CUCHILLO_IN_OUT: "EASE_CUCHILLO_IN_OUT",
  EASE_CUCHILLO_OUT: "EASE_CUCHILLO_OUT",
  EASE_IN_OUT: "EASE_IN_OUT",
  EASE_IN_OUT2: "EASE_IN_OUT2",
  EASE_BACK_IN_QUAD_OUT: "EASE_BACK_IN_QUAD_OUT",
  EASE_POWER2_IN_BACK_OUT: "EASE_POWER2_IN_BACK_OUT",
  EASE_POWER2_IN_POWER4_OUT: "EASE_POWER2_IN_POWER4_OUT"
};

CustomEase.create(Ease.EASE_CUCHILLO_IN_OUT, "M0,0 C0.5,0 0.1,1 1,1");
CustomEase.create(Ease.EASE_CUCHILLO_OUT, "M0,0c0.2,0.6,0.1,1,1,1");
CustomEase.create(Ease.EASE_IN_OUT, ".76,0,.32,.99");
CustomEase.create(Ease.EASE_IN_OUT2, ".46,.06,.56,.9");

CustomEase.create(Ease.EASE_BACK_IN_QUAD_OUT, ".45,-0.41,0,1");
CustomEase.create(Ease.EASE_POWER2_IN_BACK_OUT, ".78,.26,.12,1.23");
CustomEase.create(Ease.EASE_POWER2_IN_POWER4_OUT, ".35,.0,.0,1");


//cubic-bezier(.45,-0.41,0,1)

export { Ease };
