import gsap, { Power1 } from "gsap";
import { isDebug } from "../_app/cuchillo/core/Basics";

import { GetBy } from "../_app/cuchillo/core/Element";

export default class Preloader {
    _container;
    _progress;
    _logo;

    static init() {
        this._container = GetBy.id('Preloader');
        this._progress = GetBy.class('__progress', this._container);
        this._logo = GetBy.class('__logo', this._container);

        gsap.set(this._logo, { opacity: 0 });
        gsap.to(this._logo, {
            opacity: 1,
            ease: Power1.easeOut,
            duration: .2
        });

        if (isDebug) {
            this._container.style.display = "none";
        }
    }

    static update(__progress) {
        gsap.killTweensOf(this._progress);
        gsap.to(this._progress, {
            width: `${__progress * 100}%`,
            ease: Power1.easeOut,
            duration: .1
        });
    }

    static hide(__call) {
        gsap.killTweensOf(this._progress);
        gsap.to(this._progress, {
            width: '100%',
            ease: Power1.easeOut,
            duration: .3
        });
        gsap.to(this._logo, {
            opacity: 0,
            ease: Power1.easeOut,
            duration: .3,
            delay: .8
        });
        gsap.to(this._container, {
            duration: .5,
            opacity: 0,
            delay: .8,
            onComplete: () => {
                this._container.style.display = "none";
                if (__call) __call();
            }
        });
    }
}
