import gsap, { Back, Expo, Power2, Power3 } from "gsap";
import { ReverseSubtractEquation } from "three";
import { Basics, isTouch } from "../_app/cuchillo/core/Basics";
import { C, GetBy } from "../_app/cuchillo/core/Element";
import { Metrics } from "../_app/cuchillo/core/Metrics";
import { Ease } from "../_app/cuchillo/utils/Ease";
import { Functions } from "../_app/cuchillo/utils/Functions";
import { Maths } from "../_app/cuchillo/utils/Maths";

export class KoraTabSelector {
  static _items = [];

  static init() {
    [...GetBy.selector("[data-kora-tab-selector]")].map(dom => this._items.push(new KoraTabSelector(dom)));
  };

  static disposeAll() {
    this._items.filter(item => !item.isFixed).map(item => item.dispose());
    this._items = [];
  }

  static resizeAll() {
    this._items.map(item => item.resizeItem());
  }

  container;
  id;
  buttons = [];
  current = null;
  resizeObserver;

  constructor(__dom) {
    this.container = __dom;
    this.id = Functions.getId(__dom);

    this.setupButtons();
    this.setupResize();
    this.resize();
  }

  setupButtons() {
    [...GetBy.selector("button", this.container)].map(btn => {
      const index = this.buttons.length;

      this.buttons.push({
        button: btn,
        width: 0,
        x: 0,
      });

      //btn.addEventListener(Basics.clickEvent, e => this.changeTab(index));
    });
  }

  setupResize() {
    if (this.resizeObserver) this.resizeObserver.disconnect();
    this.resizeObserver = new ResizeObserver((entries) => {
      this.resize();
    });

    this.resizeObserver.observe(this.container);
  }

  changeTab(__index) {
    if(this.current) {
      this.current.button.classList.remove("--active");
    }

    this.current = this.buttons[__index];
    this.current.button.classList.add("--active");
    this.container.style.setProperty('--position-selector', this.current.x);
    this.container.style.setProperty('--width-selector', this.current.width);
  }

  resize() {
    const offset = this.container.getBoundingClientRect().left; 
    this.buttons.map(item =>{
      const box = item.button.getBoundingClientRect();
      item.x = box.left - offset;
      item.width = item.button.offsetWidth;
    });

    if(this.current) {
      this.container.style.setProperty('--position-selector', this.current.x);
      this.container.style.setProperty('--width-selector', this.current.width);
    }
  }

  dispose() {
    if (this.resizeObserver) this.resizeObserver.disconnect();
  }
};
