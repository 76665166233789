import gsap, { Power2 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Ease } from '../_app/cuchillo/utils/Ease';
import { Basics } from '../_app/cuchillo/core/Basics';

class ScrollItem__NewsList extends VScroll_Item {
    _categs;
    _news;
    _isHover = false;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._categs = Array.from(GetBy.class('__toggle', this.item));
        this._news = Array.from(GetBy.class('__new', this.item));

        // const titles = new SplitText(GetBy.class('__news', this.item), {
        //     type: 'lines',
        //     linesClass: 'line-child'
        // }).lines;
        // new SplitText(GetBy.class('__title', this.item), {
        //     type: 'lines',
        //     linesClass: 'line-parent'
        // }).lines;
        // gsap.set(titles, { y: '100%' });

        this._call = (e) => {
            this.handleClick(e);
        };
        this._categs.map((c) => {
            c.addEventListener(Basics.clickEvent, this._call, { passive: true });

            // const sup = GetBy.tag('sup', c)[0];

            // if (c.dataset.categ === 'all') {
            //     sup.innerHTML = this._news.length;
            // } else {
            //     sup.innerHTML = this._news.filter((p) => {
            //         return p.classList.contains(c.dataset.categ);
            //     }).length;
            // }
        });
        // gsap.set(this._categs, { opacity: 0 });

        this._hoverCall = (e) => {
            this.handleMouse(e);
        };
        this._news.map((f) => {
            f.addEventListener(Basics.mouseOver, this._hoverCall);
            f.addEventListener(Basics.mouseOut, this._hoverCall);
        });

        this.onShow = () => {
            // this._categs.map((c, i) => {
            //     gsap.to(c, {
            //         opacity: 1,
            //         delay: 1 + i * 0.05,
            //         duration: 0.5,
            //         ease: Power2.easeOut
            //     });
            // });
        };
        this.onHide = () => { };
        this.onMove = () => { };
    }

    handleClick(e) {
        const categ = e.target.dataset.categ;

        gsap.to(this._news, {
            opacity: 0,
            ease: Power2.easeInOut,
            duration: 0.3,
            onComplete: () => {
                if (e.target.dataset.categ === 'all') {
                    this._news.map((p) => {
                        p.classList.remove('--active');
                        p.classList.remove('--disabled');
                    });
                } else {
                    this._news.map((p) => {
                        if (p.classList.contains(categ)) {
                            p.classList.add('--active');
                            p.classList.remove('--disabled');
                        } else {
                            p.classList.remove('--active');
                            p.classList.add('--disabled');
                        }
                    });
                }
            }
        });

        gsap.to(this._news, {
            opacity: 1,
            ease: Power2.easeInOut,
            duration: 0.3,
            delay: 0.4
        });

        this._categs.map((c) => {
            c.classList[c === e.target ? 'add' : 'remove']('--active');
        });
    }

    handleMouse(e) {
        const titles = Array.from(GetBy.class('line-child', e.target));

        gsap.killTweensOf(titles);

        if (!this._isHover) {
            gsap.set(titles, { y: '100%' });
            titles.map((item, i) => {
                gsap.to(item, {
                    y: 0,
                    duration: 0.5,
                    delay: 0.3 + i * 0.1,
                    ease: Ease.EASE_CUCHILLO_IN_OUT
                });
            });
        } else {
            gsap.to(titles, {
                y: '100%',
                duration: 0.1,
                ease: Ease.EASE_CUCHILLO_IN_OUT
            });
        }

        this._isHover = !this._isHover;
    }
    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================
    dispose() {
        super.dispose();

        this._categs.map((c) => {
            c.removeEventListener(Basics.clickEvent, this._call);
        });
        this._news.map((f) => {
            f.removeEventListener(Basics.mouseOver, this._hoverCall);
            f.removeEventListener(Basics.mouseOut, this._hoverCall);
        });
    }

    resize(__w, __h) {
        super.resize(__w, __h);
    }
}

Scroll._registerClass('news-list', ScrollItem__NewsList);
