import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { ScrollItem__Default } from './ScrollItem__Default';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

class ScrollItem__About extends ScrollItem__Default {
  _title;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._title = GetBy.selector('h2', __link)[0];
    this._image = GetBy.class('__image', __link)[0];
    gsap.set(this._image, { opacity: 0 });

    this.onMove = () => this.loop();
    this.onShow = () => this.showEffect();
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  loop() {
    const p = 1 - this.progressInside;

    if (isSmartphone) return;

    // Title
    const opacity = Maths.clamp(Maths.map(p, .95, 1, 1, 0), 0, 1);
    gsap.set(this._title, { opacity });

    // Image
    const size = Maths.clamp(Maths.map(p, .1, .9, 80, 100), 80, 100);
    let op = Maths.clamp(Maths.map(p, .1, .15, 0, 1), 0, 1);
    if (p > .95) op = Maths.clamp(Maths.map(p, .8, .85, 1, 0), 0, 1);

    gsap.set(this._image, {
      scaleX: `${size}%`,
      scaleY: `${size}%`,
      opacity: op
    });
  }
}

Scroll._registerClass('about', ScrollItem__About);
